import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { NgPipesModule } from 'ngx-pipes';
import { NgxEchartsModule } from 'ngx-echarts';

// PIPES A-Z
import { textReplacementsPipe } from './pipes/text-replacements.pipe';


// COMPONENTS A-Z
import { AgendaComponent } from './components/agenda/agenda.component';
import { AgendaEventReportComponent } from './components/agenda-event-report/agenda-event-report.component';
import { AgendaEventViewComponent } from './components/agenda-event-view/agenda-event-view.component';
import { BasicElementsRendererComponent } from './components/basic-elements-renderer/basic-elements-renderer.component';
import { BranchedContentRendererComponent } from './components/branched-content-renderer/branched-content-renderer.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarEventComponent } from './components/calendar-event/calendar-event.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DeviceIntegrationsComponent } from './components/device-integrations/device-integrations.component';
import { DevicesSyncInfoComponent } from './components/devices-sync-info/devices-sync-info.component';
import { HtmlTemplateRendererComponent } from './components/html-template-renderer/html-template-renderer.component';
import { LanguageSelectionComponent } from './components/language-selection/language-selection.component';
import { LoadingAnimationComponent } from './components/loading-animation/loading-animation.component';
import { OhnCalendarComponent } from './components/ohn-calendar/ohn-calendar.component';
import { OhnDiaryComponent } from './components/ohn-diary/ohn-diary.component';
import { StatsChartComponent } from './components/stats-chart/stats-chart.component';
import { TabsPageComponent } from './components/tabs-page/tabs-page.component';

// BASIC COMPONENTS A-Z
import { AudioElementComponent } from './components/basic/audio-element/audio-element.component';
import { AudioEmbedElementComponent } from './components/basic/audio-embed-element/audio-embed-element.component';
import { ContentRawElementComponent } from './components/basic/content-raw-element/content-raw-element.component';
import { DateFieldElementComponent } from './components/basic/date-field-element/date-field-element.component';
import { EmbedElementComponent } from './components/basic/embed-element/embed-element.component';
import { ExternalLinkElementComponent } from './components/basic/external-link-element/external-link-element.component';
import { FitbitHeartRateComponent } from './components/basic/fitbit-heart-rate/fitbit-heart-rate.component';
import { HeartRateZonesElementComponent } from './components/basic/heart-rate-zones-element/heart-rate-zones-element.component';
import { HeightInputElementComponent } from './components/basic/height-input-element/height-input-element.component';
import { HiddenCounterComponent } from './components/basic/hidden-counter/hidden-counter.component';
import { ImageElementComponent } from './components/basic/image-element/image-element.component';
import { ItemsListComponent } from './components/basic/items-list/items-list.component';
import { ListFieldElementComponent } from './components/basic/list-field-element/list-field-element.component';
import { NoiseLevelElementComponent } from './components/basic/noise-level-element/noise-level-element.component';
import { NotesElementComponent } from './components/basic/notes-element/notes-element.component';
import { NumericFieldElementComponent } from './components/basic/numeric-field-element/numeric-field-element.component';
import { NumericPickOneComponent } from './components/basic/numeric-pick-one/numeric-pick-one.component';
import { NumericSliderElementComponent } from './components/basic/numeric-slider-element/numeric-slider-element.component';
import { PeriodToDaysSelectorComponent } from './components/basic/period-to-days-selector/period-to-days-selector.component';
import { PickManyElementComponent } from './components/basic/pick-many-element/pick-many-element.component';
import { PickOneRadioElementComponent } from './components/basic/pick-one-radio-element/pick-one-radio-element.component';
import { PrintoutReportComponent } from './components/basic/printout-report/printout-report.component';
import { RandomizationElementComponent } from './components/basic/randomization-element/randomization-element.component';
import { SessionCounterComponent } from './components/basic/session-counter/session-counter.component';
import { StringFieldElementComponent } from './components/basic/string-field-element/string-field-element.component';
import { TimerElementComponent } from './components/basic/timer-element/timer-element.component';
import { TypeaheadComponent } from './components/basic/typeahead/typeahead.component';
import { VideoElementComponent } from './components/basic/video-element/video-element.component';
import { VideoEmbedElementComponent } from './components/basic/video-embed-element/video-embed-element.component';
import { WeightInputElementComponent } from './components/basic/weight-input-element/weight-input-element.component';
import { YesNoInputElementComponent } from './components/basic/yes-no-input-element/yes-no-input-element.component';

// EVENT VIEWS COMPONENTS A-Z
import { DataframeEventViewComponent } from './components/event-views/dataframe-event-view/dataframe-event-view.component';
import { MedicationEventViewComponent } from './components/event-views/medication-event-view/medication-event-view.component';
import { MeetingEventViewComponent } from './components/event-views/meeting-event-view/meeting-event-view.component';
import { SurveyEventViewComponent } from './components/event-views/survey-event-view/survey-event-view.component';
import { WorkoutEventViewComponent } from './components/event-views/workout-event-view/workout-event-view.component';

// EVENT REPORTS COMPONENTS A-Z
import { DataframeEventReportComponent } from './components/event-reports/dataframe-event-report/dataframe-event-report.component';
import { MedicationEventReportComponent } from './components/event-reports/medication-event-report/medication-event-report.component';
import { MeetingEventReportComponent } from './components/event-reports/meeting-event-report/meeting-event-report.component';
import { SurveyEventReportComponent } from './components/event-reports/survey-event-report/survey-event-report.component';
import { WorkoutEventReportComponent } from './components/event-reports/workout-event-report/workout-event-report.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    NgPipesModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    MarkdownModule.forRoot({})
  ],
  providers: [
    MarkdownModule,
    textReplacementsPipe
  ],
  declarations: [
    // COMPONENTS A-Z
    AgendaComponent,
    AgendaEventReportComponent,
    AgendaEventViewComponent,
    BasicElementsRendererComponent,
    BranchedContentRendererComponent,
    CalendarComponent,
    CalendarEventComponent,
    DashboardComponent,
    DeviceIntegrationsComponent,
    DevicesSyncInfoComponent,
    HtmlTemplateRendererComponent,
    LanguageSelectionComponent,
    LoadingAnimationComponent,
    StatsChartComponent,
    TabsPageComponent,
    // BASIC COMPONENTS A-Z
    AudioElementComponent,
    AudioEmbedElementComponent,
    ContentRawElementComponent,
    DateFieldElementComponent,
    EmbedElementComponent,
    ExternalLinkElementComponent,
    FitbitHeartRateComponent,
    HeartRateZonesElementComponent,
    HeightInputElementComponent,
    HiddenCounterComponent,
    ImageElementComponent,
    ItemsListComponent,
    ListFieldElementComponent,
    NoiseLevelElementComponent,
    NotesElementComponent,
    NumericFieldElementComponent,
    NumericPickOneComponent,
    NumericSliderElementComponent,
    OhnCalendarComponent,
    OhnDiaryComponent,
    PeriodToDaysSelectorComponent,
    PickManyElementComponent,
    PickOneRadioElementComponent,
    PrintoutReportComponent,
    RandomizationElementComponent,
    SessionCounterComponent,
    StringFieldElementComponent,
    TimerElementComponent,
    TypeaheadComponent,
    VideoElementComponent,
    VideoEmbedElementComponent,
    WeightInputElementComponent,
    YesNoInputElementComponent,
    // EVENT VIEWS COMPONENTS A-Z
    DataframeEventViewComponent,
    MedicationEventViewComponent,
    MeetingEventViewComponent,
    SurveyEventViewComponent,
    WorkoutEventViewComponent,
    // EVENT REPORTS COMPONENTS A-Z
    DataframeEventReportComponent,
    MedicationEventReportComponent,
    MeetingEventReportComponent,
    SurveyEventReportComponent,
    WorkoutEventReportComponent,
    // PIPES
    textReplacementsPipe
  ],
  exports: [
    // COMPONENTS A-Z
    AgendaComponent,
    AgendaEventReportComponent,
    AgendaEventViewComponent,
    BasicElementsRendererComponent,
    BranchedContentRendererComponent,
    CalendarComponent,
    CalendarEventComponent,
    DashboardComponent,
    DeviceIntegrationsComponent,
    DevicesSyncInfoComponent,
    HtmlTemplateRendererComponent,
    LanguageSelectionComponent,
    LoadingAnimationComponent,
    StatsChartComponent,
    TabsPageComponent,
    // BASIC COMPONENTS A-Z
    AudioElementComponent,
    AudioEmbedElementComponent,
    ContentRawElementComponent,
    DateFieldElementComponent,
    EmbedElementComponent,
    ExternalLinkElementComponent,
    FitbitHeartRateComponent,
    HeartRateZonesElementComponent,
    HeightInputElementComponent,
    HiddenCounterComponent,
    ImageElementComponent,
    ItemsListComponent,
    ListFieldElementComponent,
    NoiseLevelElementComponent,
    NotesElementComponent,
    NumericFieldElementComponent,
    NumericPickOneComponent,
    NumericSliderElementComponent,
    OhnCalendarComponent,
    OhnDiaryComponent,
    PeriodToDaysSelectorComponent,
    PickManyElementComponent,
    PickOneRadioElementComponent,
    PrintoutReportComponent,
    RandomizationElementComponent,
    SessionCounterComponent,
    StringFieldElementComponent,
    TimerElementComponent,
    TypeaheadComponent,
    VideoElementComponent,
    VideoEmbedElementComponent,
    WeightInputElementComponent,
    YesNoInputElementComponent,
    // EVENT VIEWS COMPONENTS A-Z
    DataframeEventViewComponent,
    MedicationEventViewComponent,
    MeetingEventViewComponent,
    SurveyEventViewComponent,
    WorkoutEventViewComponent,
    // EVENT REPORTS COMPONENTS A-Z
    DataframeEventReportComponent,
    MedicationEventReportComponent,
    MeetingEventReportComponent,
    SurveyEventReportComponent,
    WorkoutEventReportComponent,
    // PIPES
    textReplacementsPipe,
    NgPipesModule
  ]
})

export class SharedModule {}
