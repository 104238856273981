import { Component, OnInit, Input, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { OhnApiService } from '../../api/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { ActionsProcessorService } from '../../services/actions-processor.service';
import { OhnLogicService } from '../../services/ohn-logic.service';
import { OHNElement, OHNLoading, OHNLogicNode, OHNUser, OHNCalendarEvent, OHNMedication, OHNMeeting, OHNBookmark } from '../../models/ohn-instances';
import { ModalController, AlertController } from '@ionic/angular';
import { Subject} from 'rxjs';
import { OhnLanguageService } from '../../services/ohn-language.service';

import { DEBUG_MODE } from '../../../environments/environment';

@Component({
  selector: 'app-branched-content-renderer',
  templateUrl: './branched-content-renderer.component.html',
  styleUrls: ['./branched-content-renderer.component.scss'],
})
export class BranchedContentRendererComponent  implements OnInit {


  @Input() parentElementSlug: string;
  @Input() user: OHNUser;
  @Input() event: OHNCalendarEvent|OHNMedication|OHNMeeting;
  @Input() parentElement : OHNElement;
  @Input() isSinglePage : boolean;
  @Input() viewOnly : boolean;
  @Input() splitSinglePageByElements : boolean;
  @Input() lockCloseButton : boolean;
  @Input() hideDoneButton : boolean;
  @Input() isOnboarding : boolean;
  @Input() inlineView : boolean;
  @Input() passedElementValues : any[]; 
  @Input() hideCloseButton : boolean; 
  @Input() inlineCommunicator : Subject<any>;
  @Input() progress : number;

  currentPage : OHNElement;

  pageStack : OHNElement[];

  basicElements : any = {};

  currentPageIndex : number = 0;

  loading : OHNLoading = new OHNLoading();

  hasBranching : boolean = true;

  finished : boolean = false;

  rules : any;

  resultPageSlug : string = '';

  shadowFlowSlugs : string[] = [];

  enableEveryStepReportPatch: boolean = false;

  reportElementStateId : string;

  contentLikesEnabled : boolean;

  displayCategoriesSelection : boolean = false;

  favoritesState : any;

  likesAlertButtons = [
    {
      text: this.lS.g('cancel'),
      role: 'cancel'
    },
    {
      text: this.lS.g('save'),
      role: 'confirm'
    },
  ];

  likesAlertInputs = [];

  hasProgressCounter : boolean;

  progressSaverInterval : any;


  constructor(
    public modalController: ModalController,
    public lS: OhnLanguageService,
    private ohnApi: OhnApiService,
    private ohnService: OhnService,
    private ohnLogicService: OhnLogicService,
    private actionsProcessor: ActionsProcessorService,
    private alertCtrl: AlertController,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    localStorage.setItem('OHNcurrentBranchedLogicUser', this.user.smart_contract);
    localStorage.setItem('OHNcurrentBranchedLogicUserName', this.user.username);
    localStorage.removeItem('ohn-radonc-pdf-recently-generated-state-id');
    localStorage.removeItem('ohn-radonc-printout-recently-generated');
    this.loading.type = 'triad';
    this.loading.show = true;
    if (this.parentElement) {
      this.initBranching();
    } else {
      this.loadParentElement();
    }
  }

  loadParentElement() {
    this.ohnApi.getElement(this.parentElementSlug, 3).subscribe(element => {
      this.parentElement = <OHNElement>element;
      this.parentElement.config = this.parentElement.config || {};
      this.hasProgressCounter = this.parentElement.config.progressCounterSlug !== undefined;
      if (this.parentElement.config.enableEveryStepReportPatch) {
        this.enableEveryStepReportPatch = true;
      }
      if (this.parentElement.config.contentLikesEnabled) {
        this.initFavorites()
      }
      this.initBranching(); 
    });
  }

  initFavorites() {
    this.ohnApi.getElement('content_bookmarks', 2).subscribe(element => {
      console.log("favs el", element);
      element.elements.forEach((e, i)=> {
        let checked : boolean = (i == 0);
        if (e.element_slug != 'all_bookmarks') {
          this.likesAlertInputs.push(
            {
              label: e.text,
              type: 'checkbox',
              value: e.element_slug,
              checked : checked
            }
          )
        }
      })
      this.contentLikesEnabled = true;
    });

    this.ohnApi.getElementStateSc('content_bookmarks', this.user.smart_contract).subscribe(state=>{
      if (!state.value) {
        this.favoritesState = { bookmarks : [] }
      } else {
        this.favoritesState = JSON.parse(state.value.value);
        this.favoritesState.bookmarks.forEach(b=>{
          let bookmarkedPage : OHNElement = this.parentElement.elements.find(p=>{return p.element_slug == b.pageSlug});
          if (bookmarkedPage) {
            bookmarkedPage.liked = true
          }
        })
      }
    })
  }

  initBranching() {
    if (this.splitSinglePageByElements) {
      let pageReplacements : OHNElement[] = [];
      this.parentElement.elements.forEach((e, i)=>{
        pageReplacements.push(<OHNElement>{
          text : e.text,
          element_slug: i.toString(),
          _cls : "Page",
          controller : "pageController",
          elements : [e]
        });
      });
      this.parentElement.elements = pageReplacements;
    }

    if (this.hasProgressCounter) {
      this.progressSaverInterval = setInterval(()=>{this.updateProgress()}, 10000);
    }

    if (this.progress && this.progress != 0) {
      this.currentPageIndex = this.progress;
      this.currentPage = this.parentElement.elements[this.progress];
    } else {
      this.currentPage = this.isSinglePage ? this.parentElement : this.parentElement.elements[0];
    }
    this.hasBranching = this.parentElement.config && (typeof this.parentElement.config.rules !== 'undefined');
    if (this.hasBranching) {
      this.rules = this.parentElement.config.rules;
      this.collectBasicElements(this.parentElement);
    }
    this.loading.show = false;
    this.shadowFlowSlugs.push(this.currentPage.element_slug);
  }

  collectBasicElements(element: OHNElement) {
    element.elements.forEach(p => {
      p.elements.forEach(e => {
        this.basicElements[e.element_slug] = e;
      });
    });
    if (this.passedElementValues) {
      this.passedElementValues.forEach(ev=>{
        this.basicElements[ev.element_slug].value = ev.value;
      })
    }
    this.ohnLogicService.init(this.rules, this.basicElements, element.elements[0].element_slug);
  }

  updateProgress() {
    this.progress = this.currentPageIndex;
    this.ohnApi.setElementStateSc(this.parentElement.config.progressCounterSlug, {value: this.progress  }, this.user.smart_contract).subscribe(counterState => {

    });
    this.inlineCommunicator.next({
      action : 'updateSurveyProgress',
      progress : this.progress,
      surveySlug : this.parentElement.element_slug
    });
    console.log(this.progress);
  }

  goNext() {
    if (!this.splitSinglePageByElements) {
      this.savePageState(this.currentPage);
    }

    if (this.enableEveryStepReportPatch) {
      this.setReportElemetntState();
    }

    if (this.hasBranching) {
      let logicNode : OHNLogicNode = this.ohnLogicService.getNextNode();
      if (logicNode) {
        this.currentPage = this.parentElement.elements.find(p => p.element_slug === logicNode.id);
      }
    } else {
      if (this.parentElement.elements[this.currentPageIndex + 1]) {
        this.currentPage = this.parentElement.elements[this.currentPageIndex + 1];
      }
    }
    if (this.currentPage.config && this.currentPage.config.isResultPage) {
      this.resultPageSlug = this.currentPage.element_slug;
    }
    this.currentPageIndex = this.parentElement.elements.findIndex(p => p.element_slug === this.currentPage.element_slug);
    this.shadowFlowSlugs.push(this.currentPage.element_slug);
  }

  goPrevious() {
    if (!this.splitSinglePageByElements) {
      this.savePageState(this.currentPage);
    }

    if (this.hasBranching) {
      if (this.ohnLogicService.currentNode.previousNodeId != '') {
        this.ohnLogicService.finalNodeReached = false;
        this.currentPage = this.parentElement.elements.find(p => p.element_slug === this.ohnLogicService.currentNode.previousNodeId);
        this.ohnLogicService.goToNode(this.ohnLogicService.currentNode.previousNodeId);
      }
    } else {
      if (this.parentElement.elements[this.currentPageIndex - 1]) {
        this.currentPage = this.parentElement.elements[this.currentPageIndex - 1];
      }
    }
    this.currentPageIndex = this.parentElement.elements.findIndex(p => p.element_slug === this.currentPage.element_slug);
    this.shadowFlowSlugs.pop();
  }

  setReportElemetntState() {
    if (this.parentElement.report_element) {
      let slugs : string[] = this.collectShadowUsedElements();
      slugs = slugs.filter((s)=>{
        return this.parentElement.report_element.elements.find((e)=>{return e.element_slug == s});
      });
      if (slugs.length > 0) {
        if (this.reportElementStateId) {
          this.ohnApi.patchElementStateSc(this.parentElement.report_element.element_slug, {value : {id : this.reportElementStateId, value: slugs }}, this.user.smart_contract).subscribe(reportState => {

          });
        } else {
          this.ohnApi.setElementStateSc(this.parentElement.report_element.element_slug, {value: slugs }, this.user.smart_contract).subscribe(reportState => {
            if (this.event) {
              this.event.report_element_state_id = reportState.id;
              let patchObject: any = (({ id, report_element_state_id, nylas_calendar_id, nylas_event_id }) => ({ id, report_element_state_id, nylas_calendar_id, nylas_event_id }))(this.event);
              this.ohnApi.patchElementStateSc('calendar_container', {value: patchObject}, this.user.smart_contract).subscribe(state => {
              });
            }
            this.reportElementStateId = reportState.id;
          });
        }
      }
    }
  }

  isFinalNode() {
    return (this.hasBranching && this.ohnLogicService.finalNodeReached) || (!this.hasBranching && this.currentPageIndex === (this.parentElement.elements.length - 1)) || this.isSinglePage;
    //return this.currentPageIndex === (this.parentElement.elements.length - 1);
  }
//add saving when done clicked
  finish() {
    this.loading.show = true;
    this.finished = true;
    if (this.splitSinglePageByElements) {
      let restoredPage : OHNElement = <OHNElement>{
        text : this.parentElement.text,
        element_slug : this.parentElement.element_slug,
        _cls : 'Page',
        controller : 'pageController',
        elements : []
      }

      this.parentElement.elements.forEach(e => {
        DEBUG_MODE && console.log('Element', e);
        restoredPage.elements.push(e.elements[0]);
      });
      this.savePageState(restoredPage);
    } else {
      this.savePageState(this.currentPage);
    }
  }

  savePageState(page : OHNElement) {
    if (this.parentElement.config.viewOnly) {
      if (this.finished) {
        this.closeModal();
      }
    } else {
      let stateObject: any = {value : {}};
      page.elements.forEach(e => {
        if (e.value !== undefined && e.value !== null) stateObject.value[e.element_slug] = e.value;
      });
      this.ohnApi.setElementStateSc(page.element_slug, stateObject, this.user.smart_contract).subscribe(state => {
        if (this.finished) {
          if (this.parentElement.config.completionCounter) {
            this.ohnApi.getElementStateSc(this.parentElement.config.completionCounter.counterSlug, this.user.smart_contract).subscribe(state =>{
              let counterValue : number = 0;
              if (state && state.value) {
                 if (state.value.value == null) {
                   counterValue = this.parentElement.config.completionCounter.startValue;
                 } else {
                   counterValue = state.value.value + 1;
                 }
              }
              this.ohnApi.setElementStateSc(this.parentElement.config.completionCounter.counterSlug, {value : counterValue}, this.user.smart_contract).subscribe(state =>{
                this.saveForReport(stateObject);
              });
            });
          } else {
            this.saveForReport(stateObject);
          }
        }
      });
    }
  }

  saveForReport(data? : any) {
    if (this.parentElement.report_element) {
      let slugs : string[] = this.collectShadowUsedElements();
      slugs = slugs.filter((s)=>{
        return this.parentElement.report_element.elements.find((e)=>{return e.element_slug == s});
      });
      if (this.reportElementStateId) {
        this.ohnApi.patchElementStateSc(this.parentElement.report_element.element_slug, {value : {id : this.reportElementStateId, value: slugs }}, this.user.smart_contract).subscribe(reportState => {
          if (this.event) {
            this.event.report_element_state_id = this.reportElementStateId;
            this.saveCompletion();
          } else {
            this.closeModal(data);
          }
        });
      } else {
        this.ohnApi.setElementStateSc(this.parentElement.report_element.element_slug, {value: slugs }, this.user.smart_contract).subscribe(reportState => {
          if (this.event) {
            this.event.report_element_state_id = reportState.id;
            this.saveCompletion();
          } else {
            this.closeModal(data);
          }
        });
      }
    } else {
      this.closeModal(data);
    }
  }

  collectShadowUsedElements(){
    let basicElementSlugs : string[] = [];
    this.shadowFlowSlugs.forEach((s)=>{
      const page = this.parentElement.elements.find((e)=>{return e.element_slug == s;});
      page.elements.forEach((el)=>{
        basicElementSlugs.push(el.element_slug)
      })
    });
    return basicElementSlugs;
  }

  saveCompletion() {
    this.event.completed = true;
    let patchObject: any = (({ id, completed, report_element_state_id, startTime, nylas_calendar_id, nylas_event_id }) => ({ id, completed, report_element_state_id, startTime, nylas_calendar_id, nylas_event_id }))(this.event);
    patchObject['completedTime'] = new Date();
    this.ohnApi.patchElementStateSc('calendar_container', {value: patchObject}, this.user.smart_contract).subscribe(state => {
      this.loading.show = false;
      this.closeModal();
    });
  }

  async showClosingDialog() {
   const alert = await this.alertCtrl.create({
      header: this.lS.g('closing'),
      message: this.lS.g('doYouWantToSaveTheResult'),
      buttons: [
        {
          text: this.lS.g('no'),
          cssClass: 'secondary',
          handler: (blah) => {
             this.resultPageSlug = '';
             this.closeModal();
          }
        }, {
          text: this.lS.g('yes'),
          handler: () => {
            this.finish();
          }
        }
      ]
    });

    await alert.present();
  }

  checkClickAction() {
    if (this.currentPage.config && this.currentPage.config.onClickAction) {
      switch(this.currentPage.config.onClickAction) {
        case "goNext" : 
          this.goNext();
          break;
      }
    }
  }

  closeModal(data? : any) {
    this.loading.show = false;
    localStorage.removeItem('OHNcurrentBranchedLogicUser');
    localStorage.removeItem('OHNcurrentBranchedLogicUserName');
    localStorage.removeItem('ohn-radonc-pdf-recently-generated-state-id');
    if (this.resultPageSlug.length > 0 && !this.finished) {
      this.showClosingDialog();
    } else {
      if (this.finished) {
        if (this.parentElement.actions && this.parentElement.actions.triggers && this.parentElement.actions.triggers.completed) {
          if (!this.parentElement.config || !this.parentElement.config.runActionsOnboardingOnly || (this.parentElement.config.runActionsOnboardingOnly && this.isOnboarding)) {
            this.actionsProcessor.processActions(this.parentElement.actions.triggers.completed, this.resultPageSlug, this.user);
          }
        }
      }
      if (this.inlineView) {
        this.closeInlineComponent()
      } else {
        this.modalController.dismiss({
          dismissed: true,
          finished : this.finished,
          data : data
        });
      }
    }
  }

  closeInlineComponent() {
    if (this.hasProgressCounter) {
      clearInterval(this.progressSaverInterval);
      this.updateProgress();
    }
    this.contentLikesEnabled = false;
    this.inlineCommunicator.next({
      action : 'close'
    });
  }

  pageNotValidated() {
    return this.currentPage.elements.find(e=>{return !e.valid})
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  toggleCategoriesAlert(state: boolean, event?: any ) {
    this.displayCategoriesSelection = state;
    if (!state && event.detail.role == 'confirm') {
      this.saveCurrentPageToFavorites(event.detail.data.values)
    }
  }

  saveCurrentPageToFavorites(categorySlug: any) {
    console.log("categorySlug", categorySlug);
    categorySlug.push('all_bookmarks');
    this.currentPage.liked = true;
    this.favoritesState.bookmarks.push(<OHNBookmark>{
      title : this.currentPage.text,
      pageSlug : this.currentPage.element_slug,
      category : categorySlug
    });
    this.saveFavoritesState()
  }

  removeCurrentPageFromFavorites() {
    this.currentPage.liked = false;
    this.favoritesState.bookmarks = this.favoritesState.bookmarks.filter(b=>{
      return b.pageSlug != this.currentPage.element_slug
    })
    this.saveFavoritesState();
  }

  saveFavoritesState() {
    console.log("this.favoritesState", this.favoritesState);
    
    this.ohnApi.setElementStateSc('content_bookmarks', {value : JSON.stringify(this.favoritesState)}, this.user.smart_contract).subscribe(response => {
      this.ohnService.widgetRefresher.next({action: 'update'});
      this.inlineCommunicator.next({action: 'updateFavs', data: this.favoritesState});
    });
    
  }

}
