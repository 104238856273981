<ng-container *ngIf="!loading.show"> 
  <ng-container *ngIf="mini"> 
    <div (click)="showCalendarModal = true;" class="mini-view today">   
      <span class="today-e">{{ date | date:'E' }}</span>
      <span class="today-d">{{ date | date:'d' }}</span>
      <span class="today-my">{{ date | date:'MMM YYYY' }}</span>
      <ion-button fill="clear" size="small">
        <ion-icon name="calendar-outline" slot="start"></ion-icon>
        My Calendar
      </ion-button>
    </div>
  </ng-container>

  <ng-container *ngIf="!mini"> 
    <ion-content>
      <div class="calendar-container">
        <div class="calendar-body">
          <div class="calendar-header">
            <ion-item lines="full">
              <ion-label><h2 class="calendar-current-date">{{months[month]}} {{year}}</h2></ion-label>
              <ion-button slot="end" (click)="changeMonth(-1)" fill="clear">
                <ion-icon name="chevron-back" slot="icon-only"></ion-icon>
              </ion-button>
              <ion-button slot="end" (click)="changeMonth(1)" fill="clear">
                <ion-icon name="chevron-forward" slot="icon-only"></ion-icon>
              </ion-button>
            </ion-item>

            <div class="calendar-weekdays">
              <div class="day-of-week">Sun</div>
              <div class="day-of-week">Mon</div>
              <div class="day-of-week">Tue</div>
              <div class="day-of-week">Wed</div>
              <div class="day-of-week">Thu</div>
              <div class="day-of-week">Fri</div>
              <div class="day-of-week">Sat</div>
            </div>
          </div>
          <div class="calendar-dates">
            <div *ngFor="let day of calendarView;" class="{{day.cls}}" (click)="selectDate(day)">
              {{day.val}}
            </div>
          </div>
        </div>
        <ion-button (click)="openEventModal()" mode="ios" expand="block" class="ion-margin">
          <ion-icon name="add" slot="start"></ion-icon>
            Add Event
        </ion-button>
      </div>

      <ion-item *ngFor="let event of selectedDayEvents;" mode="ios">
        <ion-note slot="start"><strong>{{event.startTime | date: 'shortTime'}}</strong></ion-note>
        <ion-label>
          <h2><strong>{{event.title}}</strong></h2>
          <p>{{event.description}}</p>
          <p><a href="{{event.contentObject.url}}" target="_blank">{{event.contentObject.url}}</a></p>
        </ion-label>
        <ion-button (click)="deleteEvent(event)" slot="end" fill="clear" color="medium">
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-item>

    </ion-content>
  </ng-container>

  <ion-modal [isOpen]="showCalendarModal" mode="md">
    <ng-template>      
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>My Calendar</ion-title>
          <ion-buttons slot="end">        
            <ion-button (click)="showCalendarModal = false;">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>   
        </ion-toolbar>
      </ion-header>
        <ion-content>
          <div class="calendar-container">
            <div class="calendar-body">
              <div class="calendar-header">
                <ion-item lines="full">
                  <ion-label><h2 class="calendar-current-date">{{months[month]}} {{year}}</h2></ion-label>
                  <ion-button slot="end" (click)="changeMonth(-1)" fill="clear">
                    <ion-icon name="chevron-back" slot="icon-only"></ion-icon>
                  </ion-button>
                  <ion-button slot="end" (click)="changeMonth(1)" fill="clear">
                    <ion-icon name="chevron-forward" slot="icon-only"></ion-icon>
                  </ion-button>
                </ion-item>

                <div class="calendar-weekdays">
                  <div class="day-of-week">Sun</div>
                  <div class="day-of-week">Mon</div>
                  <div class="day-of-week">Tue</div>
                  <div class="day-of-week">Wed</div>
                  <div class="day-of-week">Thu</div>
                  <div class="day-of-week">Fri</div>
                  <div class="day-of-week">Sat</div>
                </div>
              </div>
              <div class="calendar-dates">
                <div *ngFor="let day of calendarView;" class="{{day.cls}}" (click)="selectDate(day)">
                  {{day.val}}
                </div>
              </div>
            </div>
            <!--
            <ion-button (click)="openEventModal()" mode="ios" expand="block" class="ion-margin">
              <ion-icon name="add" slot="start"></ion-icon>
                Add Event
            </ion-button>
          -->
          </div>

          <ion-item *ngFor="let event of selectedDayEvents;" mode="ios">
            <ion-note slot="start"><strong>{{event.startTime | date: 'shortTime'}}</strong></ion-note>
            <ion-label>
              <h2><strong>{{event.title}}</strong></h2>
              <p>{{event.description}}</p>
              <p><a href="{{event.contentObject.url}}" target="_blank">{{event.contentObject.url}}</a></p>
            </ion-label>
            <!--
            <ion-button (click)="deleteEvent(event)" slot="end" fill="clear" color="medium">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
            -->
          </ion-item>

        </ion-content>
    </ng-template>
  </ion-modal>

  <ion-modal [isOpen]="showEventModal">
    <ng-template>      
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>New Event</ion-title>
          <ion-buttons slot="end">        
            <ion-button (click)="closeEventModal()">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>   
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item>
            <ion-input [(ngModel)]="newEvent.title" label="Title:" labelPlacement="fixed" placeholder="Enter event title here" [clearInput]="true"></ion-input>
          </ion-item>
          <ion-item>
            <ion-textarea [(ngModel)]="newEvent.description" label="Description:" labelPlacement="fixed" placeholder="Enter here"></ion-textarea>
          </ion-item>
          <ion-item>
            <ion-input [(ngModel)]="newEvent.contentObject.url" label="URL:" labelPlacement="fixed" placeholder="Enter here"></ion-input>
          </ion-item>

          <ion-item>
            <ion-label>Start Time:</ion-label>
            <ion-datetime-button datetime="datetime" slot="end"></ion-datetime-button>
          </ion-item>
          <ion-popover [keepContentsMounted]="true">
            <ng-template>
              <ion-datetime id="datetime" presentation="time" [(ngModel)]="time"></ion-datetime>
            </ng-template>
          </ion-popover>
        </ion-list>
      </ion-content>
      <ion-footer>
        <ion-toolbar color="primary">
          <ion-button (click)="addEvent()" expand="full" fill="clear" color="light">
            <ion-icon name="checkmark" slot="start"></ion-icon>
            Save Event
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    </ng-template>
  </ion-modal>

</ng-container>

<app-loading-animation *ngIf="loading.show" [aniType]="loading.type" [aniText]="loading.text"></app-loading-animation>
